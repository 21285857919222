import React, {Component} from 'react';
import styled from 'styled-components';
import {NavLink} from "react-router-dom";


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  height: calc(90vh - 65px);
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  h1{
    font-family: Calibri,sans-serif;
    font-size: 60pt;
    color: #282c34;
  }
  h2, p{
    font-family: "Raleway",sans-serif;
    font-size: 14pt;
    color: #282c34;
  }
  a, a:hover, a:visited, a:active{
    text-decoration: none;
    color: #259ccc;
  }
`;

export default class ErrorPage extends Component {
    render() {
        return (
            <PageContainer>
                <h1>Ouups...</h1>
                <p>
                    That's awkward. But don't worry, it's just a 404 error.<br/><br/>
                    You can always go back <NavLink to={"/"}> home page</NavLink>.
                </p>
            </PageContainer>
        );
    }
}