import React, {Component} from 'react';
import styled from 'styled-components';
import ContactUs from "../components/ContactUs";
import TextContainer from "../components/TextContainer";


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 30px;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
`;

export default class DigitalTransformationPage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <TextContainer>
                        <h1>Digital transformation of a company or a business</h1>
                        <p>
                            Digital transformation affects all economic processes and enables the sustainability of
                            production efficiency and enhances new business models. The smart factory manages the IT
                            networks and supports each of the smart combined technologies.
                        </p>
                        <p>
                            As an old saying goes: what works doesn't need to be repaired. For a long time, this was
                            what
                            determined the mindset of businesses: while they stopped their market position with a
                            technology or process, they did not change their operations and only began to pinch when
                            their competitors pulled past them.
                            <br/>
                            <br/>
                            The first phase of digital transformation has brought a major shift in thinking:
                            organizations have become more proactive and are actively looking for technologies to gain a
                            competitive edge. It soon became apparent that digital transformation was a complex, complex
                            process and there was no one-size-fits-all recipe.
                            <br/>
                            <br/>
                            In its second phase, digital transformation brings new challenges as technologies,
                            processes, and approaches that drive digital transformation 1.0 evolve and force the
                            transformation of the device base. Instead of extending existing folding architectures, we
                            need to rebuild networks to meet higher business demands and increased consumer
                            expectations.
                            <br/>
                            <br/>
                        </p>
                        <h2>What drives companies to digital tansformation?</h2>
                        <p>
                            Regardless of its size, stepping into a digital enterprise is about increasing efficiency,
                            and it does not go without investment: companies have to invest heavily in the application
                            and expansion of new technologies. By the end of 2019, money spent on digital transformation
                            initiatives is estimated to reach $1.7 trillion (or $ 17,000 billion) worldwide.
                            Transformation affects every level of operation and can be a major driver of global
                            productivity growth at the macro level. This growth, which dropped to 1 percent after the
                            2008 crisis, is expected to rise to 2 percent, with more than half of the increase coming
                            from digital transformation.
                        </p>
                        <h2>About AI (Artificial Intelligence)</h2>
                        <p>
                            Artificial Intelligence is now far beyond the realm of science fiction and offers us
                            opportunities in many areas beyond its human potential. AI systems analyze data from
                            enterprise and manufacturing systems and calculate trends; We support designers in designing
                            new applications and investigate bugs, which negatively impact on the user experience.
                            <br/>
                            <br/>
                            Not only does AI help companies to operate smarter, more efficiently, but also to adapt to
                            changes in market conditions, it also provides effective support for proactive operations by
                            forecasting trends.
                        </p>
                        <h2>Block Chain: The NewBie in Transformation</h2>
                        <p>
                            The blockchain doesn't seem to be the most exciting new technology, but it's important to
                            keep in mind, because it offers an extremely high level of security and interactivity, and
                            can be a great alternative to traditional filing systems. Blockchain-based smart contracts,
                            which are executed in whole or in part without human intervention, are considered by experts
                            to be an effective solution for managing escrow accounts, for example. But the health sector
                            can also use blockchains to securely share clinical trial data, and the technology is well
                            suited for managing and tracking organized data from multiple sources.
                            <br/>
                            <br/>
                            Although it is still at a relatively early stage in the development of blockchain
                            technology, it has a huge potential and the more organizations realize its potential, the
                            more important it can become as part of the digital transformation.
                        </p>
                        <h2>Have some questions? Contact us!</h2>
                    </TextContainer>
                </Container>
                <ContactUs/>
            </PageContainer>
        );
    }
}