import React, {Component} from 'react';
import styled from 'styled-components';
import iconPartners from '../assets/partners.png';

import valiantnetworks from '../assets/partners/valiantnetworks.png';
import credithome from '../assets/partners/credithome.png';
import develops from '../assets/partners/develops.png';
import elises from '../assets/partners/elises.png';
import cogito from '../assets/partners/cogito.png';
import pizzaking from '../assets/partners/pizzaking.png';


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 30px auto;
  width: 90%;
  position:relative;
  min-height: 60vh;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
  h1{
    font-family: "Raleway Thin",sans-serif;
    font-size: 28pt;
    color: dimgrey;
    width: 100%;
    text-align: left;
    padding: 20px 0;
    margin: 20px 0;
  }
  p{
    width: 60%;
    font-family: "Segoe UI",sans-serif;
    color: #282c34;
    font-size: 14pt;
    text-align: left;
  }
  .partners_icon{
    height: 340px;
    max-width: 100%;
    object-fit: scale-down;
    align-self: flex-end;
    position:absolute;
    top: 0;
  }
  
  @media screen and (max-width: 850px){
    .partners_icon{
      position:inherit;
      top: unset;
      align-self: unset;
      width: 100%;
    }
    
    p{
      width: 100%;
    }
  }
`;

const Partners = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  
  width: 75%;
  margin: 50px 40% 0 0;
  
  img{
    height: 130px;
    width: 300px;
    object-fit: scale-down;
    margin: 30px;
  }
  
  @media screen and (max-width: 850px) and (min-width: 501px){
    width: 100%;
    margin: 30px 0;
    justify-content: space-evenly;
    img{
      margin: 35px 0;
      width: 45%;
    }
  }
  
  @media screen and (max-width: 500px){
    width: 100%;
    margin: 30px 0;
    justify-content: space-evenly;
    img{
      margin: 20px 0;
      width: 70%;
    }
  }
  

`;


export default class AlliancesPage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <h1>Alliances & Partners</h1>
                    <img className={"partners_icon"} src={iconPartners} alt={"partners"}/>
                    <p>
                        We work tightly with our partners to create the most amazing solutions for our costumers.
                        Believing in teamwork.
                    </p>
                    <Partners>
                        <img src={valiantnetworks} alt={"Valiant Networks"}/>
                        <img src={develops} alt={"Develops"}/>
                        <img src={credithome} alt={"Credithome"}/>
                        <img src={elises} alt={"Elises"}/>
                        <img src={cogito} alt={"Cogito"}/>
                        <img src={pizzaking} alt={"Pizza King"}/>
                    </Partners>
                    <p>
                        {/*City Sightseeing*/}
                        {/*Gringos amigos*/}
                        {/*Marriott*/}
                        {/*Forma1*/}
                        {/*Jokai dental*/}
                        {/*The Gr1d Club*/}
                        {/*SELFIE WORLD*/}
                        {/*Mellow Mood Hotel Groups*/}
                        {/*PÁRISI UDVAR*/}

                        {/*OptiMaster*/}
                        {/*Mindtech apps*/}
                    </p>
                </Container>
            </PageContainer>
        );
    }
}