import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';
import story_icon from '../assets/story.png';
import company_image from '../assets/company.png';

const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const storyImgKeyframes = keyframes`
  0%{
    top: 0;
  }
  100%{
    top: 50px;
  }
`;

const storyImgKeyframesTablet = keyframes`
  0%{
    top: 150px;
  }
  100%{
    top: 200px;
  }
`;
const Container = styled.div`
  margin: 30px;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
  
  img{
    position:absolute;
  }
  
  .img_story{
    width: 300px;
    top:-100px;
    right: calc(4% + 90px);
    z-index: 12;
    animation: ${storyImgKeyframes} 1.5s ease-in-out 0s infinite alternate;
  }
  
  .img_company{
    width: 500px;
    top: calc(100px - 100px);
    right: 4%;
    z-index: 11;
  }
  
  @media screen and (max-width: 1550px) and (min-width: 1001px){
    .img_story{
      width: 250px;
      right: calc(2% + 50px);
      animation: ${storyImgKeyframesTablet} 1.5s ease-in-out 0s infinite alternate;
    }
    .img_company{
      width: 350px;
      right: 2%;
      top: 300px;
    }
  }
  
  @media screen and (max-width: 1000px){
    .img_story{
      display: none;
    }
    .img_company{
      position: unset;
      width: 65%;
    }
  }
`;

const TextContainer = styled.div`
  width: 76%;
  min-height: 600px;
  
  @media screen and (max-width: 850px){
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    
    p{
      width: 100%;
    }
  }
  
  h1{
    font-family: "Raleway Thin",sans-serif;
    font-size: 28pt;
    color: dimgrey;
    width: 100%;
    text-align: left;
    padding: 20px 0;
    margin: 10px 0;
  }
  h2{
    font-family: "Raleway Thin",sans-serif;
    font-size: 20pt;
    color: dimgrey;
    width: 60%;
    text-align: left;
    padding: 20px 0;
    margin-left: auto;
    margin-right: auto;
  }
  p{
    text-align: justify;
    line-height: 1.5;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-family: "Segoe UI",sans-serif;
    color: #282c34;
    font-size: 14pt;
  }
  @media screen and (max-width: 1000px){
    h1, h2{
      width: 98%;
    }
    
    p{
      width: 95%;
    }
  }
`;


export default class StoryPage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <img className={"img_story"} alt={"story_icon"} src={story_icon}/>
                    <img className={"img_company"} alt={"company_image"} src={company_image}/>
                    <TextContainer>
                        <h1>Let me tell you a story... Want to hear what happened?</h1>
                        <p>
                            Once upon a time there was a group of very talented engineers in the Budapest University of
                            Technology and Economics studying Computer Science and Software Engineering. However, these
                            guys just couldn't be satisfied with the challenges they received from their teachers. This
                            is why one of them decided to look for other opportunities, so they can set the bar to the
                            highest level and find some really cool real-world problems specific to their studying
                            field.
                            This all happened in 2016, since then, they are continuously seeking new challenges and
                            solving them with the help of the extremely forged team - which is really crazy by the way.
                        </p>
                        <h2>The beginning</h2>
                        <p>
                            We started our journey as an enthusiastic team in 2017 while attending many university
                            projects & competitions, regional and international hackathons and workshops & staying
                            hungry for knowledge and science We have learned a lot about software company management,
                            our leader knows the best what a company like ours needs for maintaining its happy
                            environment.
                        </p>
                        <h2>Nowadays</h2>
                        <p>
                            Finally in 2018 we formed ourselves as a company. In the early stages we were hired by
                            small hungarian businesses, mostly creating company websites, automating data-flow and other
                            back-office administrative routine work. This is why we started to delve deeper in the
                            automation industry.<br/>
                            Later on in 2018 we opened for international opportunities and went for scandinavian
                            (Swedish and Danish) startup companies & hungarian industrial companies. Today we have a
                            plenty friend in these countries. We always build up these connections in a friendly
                            manner.
                        </p>
                        <h2>Future</h2>
                        <p>
                            2019 - Still working hard and doing everything we can in the best quality while studying
                            the concepts of Industry 4.0 and Artificial Intelligence.
                            <br/>
                            <br/>
                            In the next years we are looking forward to new opportunities and challenges in industrial
                            automation and optimization to push the edges and create new values for our clients.
                            <br/>
                        </p>
                    </TextContainer>
                </Container>
            </PageContainer>
        );
    }
}