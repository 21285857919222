import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';
import home_bg from '../assets/home_bg.png';
import ColoredIcon from "../components/ColoredIcon";
import ContactUs from "../components/ContactUs";
import {NavLink} from "react-router-dom";

const careButtonKeyframes = keyframes`
  0%{
    transform: scale(1.0);
  }
  50%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1.0);
  }
`;

const PageContainer = styled.div`
  margin-top: 65px;
  position: relative;
  & > h2{
    width: 95%;
    padding-left: 5%;
    text-align: left;
    font-family: "Raleway Thin", sans-serif;
    font-size: 19pt;
    color: #1eb5ea;
  }
  .fullServicesText{
    width: 100%;
    text-align: center;
    font-family: "Raleway Thin", sans-serif;
    font-size: 19pt;
    color: #1eb5ea;
    margin-top: 0;
    margin-bottom: 30px; 
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 75vh;
  
  position:absolute;
  top:0;
  left:0;
  z-index: -10;
`;

const Heading = styled.div`
  h1{
      font-family: "Raleway Thin", sans-serif;
      font-size: 25pt;
      color: white;
      a, a:hover, a:visited, a:active{
        text-decoration: none;
        color: white;
      }  
      span{
        padding: 10px 20px;
        background-color: #0576af;
        border-radius: 7px;
        font-family: Raleway,sans-serif;
        display: inline-block;
        animation: ${careButtonKeyframes} 1s ease-in-out alternate;
        &:hover, &:active{
          background-color: #0495df;
          cursor: pointer;
        }
      }
  }
  height: 65vh;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

const Transition = styled.div`
  width: 80%;
  margin-left: 10%;
  height: 20vh;
  border-radius: 8px;
  background-image: linear-gradient(0, white, rgba(97,218,251,0.26));
  h1{
    padding-top: 10vh;
    font-family: "Raleway Thin", sans-serif;
    font-size: 28pt;
    color: #272829;
  }
  
  @media screen and (max-width: 650px){
    h1{
      font-size: 15pt;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  div{
    min-width: 350px;
    max-width: 25%;
    min-height: 250px;
    border-radius: 5px;
    p{
      text-align: left;
      font-size: 16pt;
    }
  }
  
  @media screen and (max-width:350px){
    div{
       min-width: 300px;
       p{
        font-size: 11pt;
       }
    }
  }
  
  @media screen and (max-width:650px) and (min-width: 351px){
    div{
      p{
        font-size: 13pt;
      }
    }
  }
`;

export default class HomePage extends Component {
    render() {
        return (
            <PageContainer>
                <Image src={home_bg}/>
                <Heading>
                    <h1>IT Engineering</h1>
                    <h1>That's What We <NavLink to={"/passion"}><span
                        className={"careButton"}>Care</span></NavLink> About</h1>
                </Heading>
                <Transition>
                    <h1>Not just Software; made by <span aria-label={"coffee"} role={"img"}>☕️ </span>with <span
                        aria-label={"heart"} role={"img"}>❤️</span>.</h1>
                </Transition>
                <h2>Our services in Budapest</h2>
                <Container>
                    <div>
                        <p><ColoredIcon className="fas fa-code"/>Custom Software Development</p>
                        <span>
                            You can hire us to make any kind of software for your company. All needs of a complete
                            software (design, implementation, testing, maintenance) are done with love, cofee & the
                            latest technologies used.
                        </span>
                    </div>
                    <div>
                        <p><ColoredIcon className="fas fa-cogs"/>Automation & Optimization</p>
                        <span>
                            We like engineering. We can help you save a lot of expenses for the company when it comes to routine work
                            by relying on integrated solutions run by computers. No one likes to do the same thing 28 times a day!
                        </span>
                    </div>
                    <div>
                        <p>
                            <ColoredIcon style={{marginRight: "0"}} className="fab fa-app-store"/>
                            <ColoredIcon style={{marginLeft: "10px"}} className="fab fa-google-play"/> Web & Mobile Apps
                        </p>
                        <span>
                            We offer our fast delivered iOS / Android mobile app development services as
                            well as our websites and web design competitiveness.
                            Both front end and back end (full stack) development will be provided with documentation,
                            specifications and maximum customer support.
                        </span>
                    </div>
                </Container>
                <h3 className={"fullServicesText"}>Read more in the 'What we do' menu.</h3>
                <ContactUs/>
            </PageContainer>
        );
    }
}