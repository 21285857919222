import styled from 'styled-components';

const TextContainer = styled.div`
  width: 100%;
  min-height: 600px;
  
  img{
    width: 100%;
    max-height: 75vh;
    height: auto;
    object-fit: scale-down;
  }
  
  h1{
    font-family: "Raleway",sans-serif;
    font-size: 28pt;
    color: dimgrey;
    width: 70%;
    text-align: left;
    padding: 20px 0;
    margin-left: auto;
    margin-right: auto;
  }
  h2{
    font-family: "Raleway Thin",sans-serif;
    font-size: 20pt;
    color: dimgrey;
    width: 60%;
    text-align: left;
    padding: 20px 0;
    margin-left: auto;
    margin-right: auto;
  }
  p{
    text-align: justify;
    line-height: 1.5;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-family: "Segoe UI",sans-serif;
    color: #282c34;
    font-size: 14pt;
  }
  @media screen and (max-width: 1000px){
    h1, h2{
      width: 98%;
    }
    
    p{
      width: 95%;
    }
  }
`;

export default TextContainer;