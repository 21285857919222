import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import styled from 'styled-components';

const Foot = styled.footer`
    padding: 0 20px 0 10px;
    display: flex;
    width: 100%;
    min-height: 300px;
    box-sizing: border-box;
    background-image: linear-gradient(300deg, rgb(26,157,203), rgb(5,118,175));
    /*background-color: #0A0F25;*/
    color: white;
    font-size: 15pt;
    font-family: "Open Sans", sans-serif;
    z-index: 10;
    
    .footerContainer{
      width: 100%;
      margin-top: 20px;
      padding: 0 5vw 0 5vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    
    @media screen and (max-height: 400px) {
      .footerContainer {
      margin-top: 40vh;
      }
    }
    
    .fullWidthFooterElement {
      width: 100%;
    }
    
    .footerElement {
      margin: 10px 5vw 10px 5vw;
      p{
        text-align: left;
        margin-left: 30px;
      }
    }
    
    .copyright {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
      font-size: 9pt;
      a, a:hover, a:visited{
        font-size: 9pt;
      }
    }
    
    a, a:hover, a:visited {
      color: white !important;
      text-decoration: none;
      font-size: 12pt;
    }
`;

export default class Footer extends Component {
    render() {
        let year = new Date().getFullYear();
        return (
            <Foot>
                <div className={"footerContainer"}>
                    <div className={"footerElement"}>
                        <h5>About us</h5>
                        <NavLink to={"/story"}>Company story</NavLink><br/>
                        {/*<NavLink to={"/csapat"}>Mission</NavLink><br/>*/}
                        <NavLink to={"/passion"}>Passion</NavLink><br/>
                    </div>
                    <div className={"footerElement"}>
                        <h5>Our services</h5>
                        <NavLink to={"/automation"}>Automation & Optimization</NavLink><br/>
                        <NavLink to={"/digital_transformation"}>Digital transformation</NavLink><br/>
                        <NavLink to={"/custom_software"}>Custom Software Development</NavLink><br/>
                        <NavLink to={"/mobile"}>Mobile</NavLink><br/>
                        <NavLink to={"/web"}>Web development</NavLink><br/>
                    </div>
                    <div className={"footerElement"}>
                        <h5>Contact us</h5>
                        <p>
                            <a href={"tel:+36702874064"}>
                                <i className={"fas fa-phone"}/> +36 70 287 4064
                            </a><br/>

                            <a href={"mailto:letstalk [at] opthink.com"}>
                                <i className={"fas fa-envelope"}/> letstalk [at] opthink.com
                            </a><br/>

                            <a href={"https://www.google.com/maps/place/Hal%C3%A1sztelek,+T%C3%A1ncsics+Mih%C3%A1ly+u.+82,+2314/@47.3592436,18.9733043,17z/data=!3m1!4b1!4m5!3m4!1s0x4741e431e5d4276b:0xc46ff6601a11209a!8m2!3d47.35924!4d18.975493"}>
                                <i className={"fas fa-map-marker-alt"}/> Hungary, 2314 Halásztelek, Táncsics M. str. 82
                            </a><br/>
                        </p>
                        {/*<a href={"https://www.facebook.com/opthink/"}>*/}
                        {/*    <i className={"fab fa-facebook-f"}/> Facebook page*/}
                        {/*</a><br/>*/}

                    </div>
                    <div className={"copyright"}>
                        Copyright © 2017 - {year} OpThink, XYZ OpThink Group Kft. (MP Spare Parts Kft.).
                    </div>
                </div>
            </Foot>
        );
    }
}
