import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';
import story_icon from '../assets/story.png';
import company_image from '../assets/company.png';
import passion from '../assets/passion.jpg';
import ColoredIcon from "../components/ColoredIcon";
import ContactUs from "../components/ContactUs";

const PageContainer = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  
  & > h1{
    position:absolute;
    top: 20vh;
    color: white;
    font-family: "Raleway Thin", sans-serif;
    font-size: 50pt;
  }
  & > img{
    width: 100%;
    object-fit: cover;
    height: 70vh;
  }
`;

const storyImgKeyframes = keyframes`
  0%{
    top: -100px;
  }
  100%{
    top: -50px;
  }
`;

const storyImgKeyframesTablet = keyframes`
  0%{
    top: 150px;
  }
  100%{
    top: 200px;
  }
`;

const Container = styled.div`
  width: 90%;
  padding: 20px 0;
  margin: 30px 0;
  position: relative;
  min-height: 60vh;
  
  img{
    position:absolute;
  }
  
  .img_story{
    width: 300px;
    top:-100px;
    right: calc(4% + 90px);
    z-index: 12;
    animation: ${storyImgKeyframes} 1.5s ease-in-out 0s infinite alternate;
  }
  
  .img_company{
    width: 500px;
    top: calc(100px - 100px);
    right: 4%;
    z-index: 11;
  }
  
  @media screen and (max-width: 1550px) and (min-width: 1001px){
    .img_story{
      width: 250px;
      right: calc(2% + 50px);
      animation: ${storyImgKeyframesTablet} 1.5s ease-in-out 0s infinite alternate;
    }
    .img_company{
      width: 350px;
      right: 2%;
      top: 300px;
    }
  }
  
  @media screen and (max-width: 1000px){
    .img_story{
      display: none;
    }
    .img_company{
      position: unset;
      width: 65%;
    }
  }
`;

const TextContainer = styled.div`
  width: 76%;
  min-height: 600px;
  
  @media screen and (max-width: 650px){
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  
  h1{
    font-family: "Raleway Thin",sans-serif;
    font-size: 28pt;
    color: dimgrey;
    width: 100%;
    text-align: left;
    padding: 20px 0;
    margin: 10px 0;
  }
  p{
    text-align: justify;
    line-height: 1.5;
    width: 60%;
    font-family: "Segoe UI",sans-serif;
    color: #282c34;
    font-size: 14pt;
  }
`;

const Values = styled.div`
  width: 90%;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  
  @media screen and (max-width: 650px){
    width: 98%;
    margin-left: 1%;
    div{
      top: 0;
    }
  }
  
  & > div{
    width: 330px;
    height: 200px;
    margin: 50px 25px 50px 25px;
    position: relative;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i{
      margin: 0;
    }
    & > h2{
      font-family: "Raleway Light",sans-serif;
      color: #282c34;
      margin-bottom: 4px;
      margin-top: 5px;
    }
    & > p{
      margin-top: 4px;
      text-align: center;
      width: 90%;
      font-size: 12pt;
    }
  }
  
  & > div:nth-child(1){top:-5px;}
  & > div:nth-child(2){top:30px;}
  & > div:nth-child(3){top:0;}
  & > div:nth-child(4){top:-50px;}
  & > div:nth-child(5){top:-10px;}
  & > div:nth-child(6){top:-50px;}
`;

export default class PassionPage extends Component {
    render() {
        return (
            <PageContainer>
                <img src={passion} alt={"passion"}/>
                <h1>Passion</h1>
                <Container>
                    <img className={"img_story"} alt={"story_icon"} src={story_icon}/>
                    <img className={"img_company"} alt={"company_image"} src={company_image}/>
                    <div/>
                    <TextContainer>
                        <h1>Read more on our dedicated love about...</h1>
                        <Values>
                            <div>
                                <ColoredIcon className={"fas fa-gem"}/>
                                <h2>Creating values for other firms</h2>
                                <p>We are the most thankful when we see how our products help the people of the
                                    business.</p>
                            </div>
                            <div>
                                <ColoredIcon className={"fas fa-puzzle-piece"}/>
                                <h2>Solving problems</h2>
                                <p>
                                    Solving challenging and exciting real world problems is one of pillars of our
                                    existence.
                                </p>
                            </div>
                            <div>
                                <ColoredIcon className={"far fa-check-circle"}/>
                                <h2>Aiming to 100% Satisfaction</h2>
                                <p>
                                    "There are some days when I think I'm going to die from an overdose of
                                    satisfaction." ~Salvador Dali<br/>
                                    One of our costumer sent us this quote.
                                </p>
                            </div>
                            <div>
                                <ColoredIcon className={"fas fa-infinity"}/>
                                <h2>Always learning new stuff</h2>
                                <p>
                                    That's part of a healthy company.
                                </p>
                            </div>
                            <div>
                                <ColoredIcon className={"fas fa-chart-line"}/>
                                <h2>Planning</h2>
                                <p>
                                    We plan for the long-term not just to hustle once or twice. This is why aiming at
                                    100% satisfaction is important.
                                </p>
                            </div>
                            <div>
                                <ColoredIcon className={"fas fa-spinner"}/>
                                <h2>Teaching</h2>
                                <p>
                                    Teaching / helping each other and our clients is fundamental for a balanced and
                                    trustworthy group of people
                                </p>
                            </div>
                        </Values>
                        {/*<p>*/}
                        {/*    Fast and reliable*/}
                        {/*    Comfortable services, we aim at 100% costumer satisfaction.*/}
                        {/*    Innovative thinking*/}
                        {/*    Precise engineering - don't you think double checking should be the new standard?*/}
                        {/*    No way of giving out not perfect work from our hands.*/}
                        {/*    Our products are designed for high cost efficiency*/}
                        {/*</p>*/}
                        <h1>If you think you can connect, knock in! We don't bite!</h1>
                    </TextContainer>
                </Container>
                <ContactUs/>
            </PageContainer>
        );
    }
}