import React, {Component} from 'react';
import styled from 'styled-components';
import agile_cycle from '../assets/agile_cycle.jpg';
import TextContainer from "../components/TextContainer";


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 30px;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
`;


export default class AgilePage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <TextContainer>
                        <h1>Agile mindset - Scrum methodology</h1>
                        <p>
                            At the beginning of software development, it was believed that development is a linear
                            process that requires proper attention and techniques in the first stages, and then you will
                            be successful. Numerous failed software development projects have proven this to be a
                            mistake.
                            <br/>
                            <br/>
                            If you are 40+ years old, you may have had the experience of beginning your career in
                            software development at your company that ended in failure. By the time the software was
                            created, it turned out that the client didn't need what the developers had been working on
                            for months. I started my career at a software development company and I remember them well.
                            I assume that many development failures have contributed to, or rather forced, the solution:
                            the agile methodology.
                        </p>
                        <h2>What does Scrum mean?</h2>
                        <p>
                            After the concept of agile software development was born, a number of methodological
                            approaches (Feature Driven Development, Scrum, Test Driven Development, Kanban, Crystal
                            Clear) came to light, of which Scrum methodology has gained the most recognition.
                            <br/>
                            <br/>
                            Scrum is a cost-effective framework for software development. With this method, companies
                            can easily develop and support a product in a complex and dynamic environment.
                            <br/>
                            <br/>
                            Scrum is the answer to the rapid evolution of technology and the rapid change in customer
                            needs. The starting point of the method is experience: we try, learn from our experiences,
                            and decide how to proceed.
                            <br/>
                            <br/>
                            So in short, Scrum is one type of methodology which leans towards - or we can say obeys
                            the rules of - the abstract Agile mindset.
                        </p>
                        <h2>How does it work?</h2>
                        <p>
                            In the Scrum methodology, an iterative development cycle is called a sprint. Product backlog
                            is the list of the remaining tasks to do in one sprint. Scrum assumes that there is a broad
                            knowledge of the scope of the product to be produced during the sprint.
                            <br/>
                            <br/>
                            Scrum assumes smaller development teams (3-9 people). It distinguishes between two roles,
                            "Product owner" who can essentially formulate the functionality expected of the software
                            being developed, and a "Scrum master" who understands and enforces Scrum rules responsible.
                            <br/>
                            <br/>
                            <img src={agile_cycle} alt={"scrum cyle"}/>
                            Other members of the development team are not named by name. Everyone is considered a
                            developer, regardless of whether they are a software developer, business analyst, tester,
                            document writer or UX / UI designer responsible for the platform.
                            <br/>
                            <br/>
                            A special feature of the Scrum method is the self-organization of the development team.
                            Accordingly, in Scrum, team members voluntarily (self-organize) undertake sprint tasks. The
                            duration of a sprint is up to one month. The time required for a task in the first parts of
                            a sprint is up to one business day.
                            <br/>
                            <br/>
                            They hold meetings called "Daily Scrum", also known as "Daily Stand-Up", to help plan the
                            next 24 hours. The daily Scrum can last up to 15 minutes, with each participant answering
                            three questions:
                            <ul>
                                <li>What have you done since the last meeting?</li>
                                <li>What will you do until the next meeting?</li>
                                <li>What obstacles do you see?</li>
                            </ul>
                            At the end of the sprint, a "sprint reviewer", also known as a "sprint retrospective" or
                            "retrospective", takes place to review the completed and unfinished parts, and to examine
                            its causes and possible consequences.
                            <br/>
                            <br/>
                            At this point it is also possible to discuss how the implementation of the whole product
                            stands. In this case, they can also evaluate measures to improve the development team and
                            method. After that, the next sprint can be planned, often with functionality that can be
                            used immediately when the sprint finishes successfully.
                        </p>
                    </TextContainer>
                </Container>
            </PageContainer>
        );
    }
}