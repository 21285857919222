/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import HeaderLogo from "./HeaderLogo";
import HamburgerMenuIcon from "./HamburgerMenuIcon";
import styled, {css} from 'styled-components';
import icon_blue from '../../assets/icon_blue.png';
import icon_white from '../../assets/icon_white.png';
import EventSystem from "../../utils/EventSystem";

const NavBar = styled.div`
    width: 100%;
    height: 60px;
    padding: 0 5px 5px 5px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 19;

    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    color: rgb(18, 25, 69);
    /*choose*/
    background-color: rgba(255, 255, 255, 0.19);
    /*background-image: linear-gradient(45deg, rgba(141, 21, 22, 0.9), rgba(18, 25, 69, 0.9), rgba(255, 68, 70, 0.9));*/
    transition: background-color 200ms ease-in-out;

    ${({scrolled}) => scrolled && css`
        background-color: white;
        box-shadow: 0 3px 5px rgb(61, 61, 61);
    `}
    
    .logo-navlink{
        height: 100%;
        box-sizing: border-box;
    }
`;

const NavMenu = styled.div`
    transform-origin: right;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    width: 100%;

    padding-right: 10px;
    
    @media screen and (max-width: 951px) {
        width: 80vw;
        height: calc(100vh);

        position: absolute;
        right: 0;
        top: 0;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 20px 30px 20px 30px;

        background-color: rgba(30,181,234,0.91);
        z-index: 0;
        box-shadow: black -1px 0 15px 1px;
        
        transition: transform 400ms ease-in-out;
        ${({menuOpened}) => !menuOpened && css`
            transform: translateX(100%);
        `}
        
        ${({menuOpened}) => menuOpened && css`
        `}
    }
`;

const MenuItemStyles = css`
    &:before{
      background-image: url(${icon_blue});
      background-size: 0 0;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 0 5px 0 0;
      content: '';
      position: relative;
      top: 3px;
      
      transition: background-size .1s ease-in-out;
    }
    
    @media screen and (max-width: 951px){
        ${props => props.clicked && css`
          text-decoration: none;
          &:before{
            background-size: 100% 100%;
          }
        `};
    }
     
    @media screen and (min-width: 952px){
        &:hover, &:active{
          text-decoration: none;
          &:before{
            background-size: 100% 100%;
          }
        }
    }
        
    font-family: "Raleway Light", sans-serif;
    font-size: 21pt;
    color: #1a9dcb;
    text-decoration: none;
    border: 1px solid rgba(18, 25, 69, 0);
    transition: border-radius 200ms ease-in-out;
    
    display: block;
    padding: 5px 0;
    background-color: rgba(229, 64, 65, 0);
    margin: 0 10px 0 10px;
    position: relative;
    
    @media screen and (max-width: 951px) {
        color: white;
        font-size: 1.12em;
        padding: 15px 10px 15px 10px;
        &:before{
          background-image: url(${icon_white});
        }
    }
`;

const MenuItem = styled(NavLink)`
    ${MenuItemStyles}
`;


const MenuDropdownItem = styled(MenuItem)`
  &:before{
    width: 15px;
    height: 15px;
    background-image: url(${icon_white});
  }
  color: white;
  font-size: 14pt;
  
`;

const MenuDropdownItems = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-content: start start;
  overflow: visible;
  background-color: #77cbff;
  z-index: 2;
  width: 200px;
  @media screen and (max-width: 951px) {
    //width: 80vw;
    //left: 10%; //relative not to the container but the page
  }

  ${MenuDropdownItem}{
    text-align: left;
  }

  border-radius: 4px;
  border: 1px solid #61dafb;

  position: absolute;
  top: 100%;
  left: 0;
`;

// eslint-disable-next-line no-unused-vars
// noinspection JSUnusedLocalSymbols
const MenuDivider = styled.div`
  width: 90%;
  padding: 0 5%;
  margin: 0 auto;
  box-sizing: border-box;

  height: 1px;
  background-color: white;
`;

const MenuDropDownClickedStyle = css`
    ${MenuDropdownItems}{
      @media screen and (max-width: 700px){
        height: 60vh;
        left: 0;
        top: 40vh;
        width: 100%;
        flex-wrap: nowrap;
        align-items: center;
        overflow-y: scroll;
      }
      @media screen and (max-width: 951px) and (min-width: 701px){
          height: 40vh;
          left: 0;
          top: 60vh;
          width: 100%;
          flex-wrap: nowrap;
          align-items: center;
          overflow-y: scroll;
      }
      display: flex;
      left: 0;
      top: 100%;
      width: 200px;
    }
    @media screen and (max-width: 951px){
      position: unset;
    }
    overflow: visible;
`;

const MenuDropDown = styled.div`
  ${MenuItemStyles}
  
  ${MenuDropdownItems} {
    display: none;
  }
  display: block;
  
  @media screen and (min-width: 952px) {
      &:hover, &:active{
        ${MenuDropDownClickedStyle}
      }
  }
  @media screen and (max-width: 951px) {
    ${props => props.clicked && MenuDropDownClickedStyle};
  }
  
`;

class MenuDropdown extends Component {
    static i = 0;

    clickEvent() {
        EventSystem.publish("menudropdownclicked", {i: this.state.i});
        this.setState({clicked: !this.state.clicked});
    }

    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            i: MenuDropdown.i,
        };
        MenuDropdown.i++;
        EventSystem.subscribe("menudropdownclicked", (data) => {
            if (data.i !== this.state.i) {
                this.setState({clicked: false});
            }
        });
    }

    render() {
        return (
            <MenuDropDown onClick={() => this.clickEvent()} clicked={this.state.clicked}>
                {this.props.children}
            </MenuDropDown>
        );
    }
}

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            scrolled: false
        };
    }

    menuStateChanged(opened) {
        this.setState({menuOpened: opened});
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                this.setState({scrolled: true});
            } else {
                this.setState({scrolled: false});
            }
        })
    }

    render() {
        return (
            <NavBar scrolled={this.state.scrolled}>
                <NavLink className={"logo-navlink"} to={"/"}>
                    <HeaderLogo/>
                </NavLink>
                <NavMenu menuOpened={this.state.menuOpened}>
                    {/*<MenuItem to={"/why"}>Why we do it</MenuItem>*/}
                    {/*<MenuItem to={"/what"}>What we do</MenuItem>*/}
                    {/*<MenuItem to={"/how"}>How we do it</MenuItem>*/}
                    <MenuDropdown>
                        Why we do it
                        <MenuDropdownItems>
                            <MenuDropdownItem to="/story">Company story</MenuDropdownItem>
                            {/*<MenuDropdownItem to="/mission">The mission</MenuDropdownItem>*/}
                            <MenuDropdownItem to="/passion">Our passion</MenuDropdownItem>
                            {/*<MenuDivider/>*/}
                            {/*<MenuDropdownItem to="/seperated">Separated link</MenuDropdownItem>*/}
                        </MenuDropdownItems>
                    </MenuDropdown>
                    <MenuDropdown to={"#"}>
                        What we do
                        <MenuDropdownItems>
                            <MenuDropdownItem to="/automation">Automation & Optimization</MenuDropdownItem>
                            <MenuDropdownItem to="/digital_transformation">Digital transformation</MenuDropdownItem>
                            {/*<MenuDropdownItem to="/testing">Software Testing</MenuDropdownItem>*/}
                            <MenuDropdownItem to="/mobile">Mobile</MenuDropdownItem>
                            <MenuDropdownItem to="/web">Web</MenuDropdownItem>
                            <MenuDropdownItem to="/custom_software">Custom Software</MenuDropdownItem>
                        </MenuDropdownItems>
                    </MenuDropdown>
                    <MenuDropdown to={"#"}>
                        How we do it
                        <MenuDropdownItems>
                            <MenuDropdownItem to="/technologies">Used technologies</MenuDropdownItem>
                            <MenuDropdownItem to="/devops">DevOps</MenuDropdownItem>
                            <MenuDropdownItem to="/agile">Agile</MenuDropdownItem>
                            <MenuDropdownItem to="/partners">Alliances</MenuDropdownItem>
                        </MenuDropdownItems>
                    </MenuDropdown>
                </NavMenu>
                <HamburgerMenuIcon menuStateChanged={this.menuStateChanged.bind(this)}/>
            </NavBar>
        );
    }
}