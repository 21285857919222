import styled from "styled-components";

const ColoredIcon = styled.i`
  font-size: 45px;
  
  //background: -webkit-linear-gradient(45deg, #7c3c9a 0%, #7c3c9a 45%, #4c46a7 55%, #4c46a7 100%);
  background: -webkit-linear-gradient(20deg, #002c47 0%, #5994ff 45%, #61dafb 55%, #259ccc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 20px;
`;

export default ColoredIcon;