const baseURL = 'https://www.opthink.com/backend';

function ownFetch(method, url, params, callback) {
    console.log(baseURL + url + '::' + method + ' called');
    let formData = new URLSearchParams();

    if (params) {
        for (let key in params)
            if (params.hasOwnProperty(key))
                formData.append(key, params[key]);
    }

    let init = {
        method: method,
        headers: {
            'Accept': 'application/text',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        credentials: 'include',
        withCredentials: true,
    };

    if (method !== 'GET') {
        init.body = formData;
    }

    fetch(baseURL + url, init
    ).then(res => {
        return res.text();
    }).then(
        res => {
            if (callback)
                callback(res);
        }
    ).catch(reason => {
        console.log(reason);
    });
}

export default {
    baseURL,
    sendContactForm(name, email, tel, message, topic) {
        ownFetch("POST", "/contact-us-request.php", {
                name, email, tel, message, topic
            },
            (res) => {
                console.log(res)
            }
        );
    },
};
