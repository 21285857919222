import React, {Component} from 'react';
import styled from 'styled-components';
import devops_cycle from '../assets/devops_cycle.png';
import TextContainer from "../components/TextContainer";


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 30px;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
`;


export default class DevOpsPage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <TextContainer>
                        <h1>Development and Operations - Dev&Ops</h1>
                        <p>
                            Traditionally, the process of developing any kind of software product is accomplished by the
                            product manager, the business analyst and the developer working together to develop a
                            roadmap and rethink it every step of the way. Theoretically, at the end of each step, there
                            should be a release version, which is not always the case. Most often, the final results of
                            each step only gets to the test roll, as the actual release requires further steps:
                            packaging,
                            environmental assessment, and collaboration with operators.
                            <br/>
                            <br/>
                            People are starting to realize that the common "throw it across the wall" mentality in
                            collaboration between development and operations departments created at least as much
                            challenge as when we were expecting to throw code back in response to our instructions while
                            programming.
                            <br/>
                            <br/>
                            The boundaries between development and operational tasks are beginning to blur, so "DevOps"
                            was born, as its name implies.
                        </p>
                        <img src={devops_cycle} alt={"devops cycle"}/>
                        <h1>Why is it good for the customer?</h1>
                        <h2>Transparency</h2>
                        <p>
                            The principles of the Agile methodology are becoming more and more important in the life of
                            companies, and software products are increasingly being marketed according to the principle
                            of Continuous Deployment. Of course, no one would be happy if a new functionality had to be
                            improved months later. When we add a new feature to our product, it's best to get instant
                            feedback on how it works. This way, development can quickly fix important bugs.
                            <br/>
                            <br/>
                            But how can you make sure your employees give you product feedback as quickly as possible?
                            For many organizations, the answer to this question is DevOps. Development is not only
                            responsible for the product but also for its successful use. And the idea is to work with
                            them during the development process.
                        </p>
                        <h2>Using the same applications</h2>
                        <p>
                            Development uses and maintains systems that Operations has a lot of experience with, such as
                            automated testing and code repository. Why can't development use the same systems as
                            operation?
                            <br/>
                            <br/>
                            It is even more efficient to have these applications hosted by Operations for both parties,
                            as this is the area where they have the most experience. If your company is ready for this,
                            centralized management of its DTAP infrastructure (Development, Testing, Acceptance,
                            Production).It's also a smart decision in SaaS environments.
                        </p>
                        <h2>More efficient maintenance of new technologies</h2>
                        <p>
                            As IT infrastructures become more and more complex, process automation becomes increasingly
                            important to operational teams. For example, running 1500 SaaS environments at one time is
                            more efficient than updating them manually. As a result, Operations is more and more
                            involved in code work as it does in development. Therefore, for example, it is advisable to
                            manage a so-called "desired state configuration" as centrally as possible, for which you can use
                            version management systems such as Git.
                            <br/>
                            <br/>
                            The key to calling your company a DevOps organization lies in working together in the same
                            environments and applications and delivering new functionality in the most efficient way.
                            The icing on the cake is that this will not result in two separate teams working within your
                            company who reinvent the wheel again and again.
                        </p>
                    </TextContainer>
                </Container>
            </PageContainer>
        );
    }
}