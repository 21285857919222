import React, {Component} from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import {polyfill} from 'es6-promise';
import "babel-polyfill";
import './App.css';
import styled from 'styled-components';

import {BrowserRouter, Route, Switch} from "react-router-dom"
import ScrollToTop from "./utils/ScrollToTop";
import Header from "./components/header/Header";
import HomePage from "./pages/HomePage";
import Footer from "./components/footer/Footer";
import StoryPage from "./pages/StoryPage";
import ErrorPage from "./pages/ErrorPage";
import MissionPage from "./pages/MissionPage";
import PassionPage from "./pages/PassionPage";
import AutomationPage from "./pages/AutomationPage";
import DigitalTransformationPage from "./pages/DigitalTransformationPage";
import SoftwareTestingPage from "./pages/SoftwareTestingPage";
import MobilePage from "./pages/MobilePage";
import WebDevelopmentPage from "./pages/WebDevelopmentPage";
import CustomPage from "./pages/CustomPage";
import UsedTechnologiesPage from "./pages/UsedTechnologiesPage";
import AlliancesPage from "./pages/AlliancesPage";
import AgilePage from "./pages/AgilePage";
import DevOpsPage from "./pages/DevOpsPage";
import EventSystem from "./utils/EventSystem";
import ReactGA from 'react-ga';

//polyfill-ing other languages (e.g.: es6 can work on ie as well)
polyfill();

function fontawesomeloader() {
    var css = document.createElement('link');
    css.href = 'https://use.fontawesome.com/releases/v5.1.0/css/all.css';
    css.rel = 'stylesheet';
    css.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(css);
}

fontawesomeloader();

const CookiesNotification = styled.div`
  width: 80%;
  height: 6vh;
  position: fixed;
  top: calc(94vh - 14px);
  z-index: 100;
  left: 0;
  background-color: rgb(37,156,204);
  padding: 7px 10%;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  
  span{
    color: black;
    font-family: 'Open sans', sans-serif;
    font-size: 12pt;
    text-align: left;
  }
  
  div{
    border-radius: 3px;
    border: 1px solid black;
    width: fit-content;
    padding: 5px 10px;
    &:hover, &:active{
      cursor: pointer;
      background-color: #97e9fb;
    }
  }
  
  @media screen and (max-width: 800px){
    padding: 1px 7px;
    width: calc(100% - 14px);
    
    height: 20vh;
    top: calc(80vh - 2px);
    span{
      font-size: 11pt;
    }
  }
`;

class App extends Component {

    state = {
        cookie: false,
    };

    componentDidMount() {
        this.setState({
            cookie: window.localStorage.getItem("cookie") === "1",
        });
        ReactGA.initialize('UA-157615013-1');
        ReactGA.set({page: window.location.pathname}); // Update the user's current page
        ReactGA.pageview(window.location.pathname); // Record a pageview for the given page

        EventSystem.subscribe('urlChanged', () => {
            //Google analytics pagerefresh send
            ReactGA.set({page: window.location.pathname}); // Update the user's current page
            ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
            //fb pixel pageview send
            //FBPixel.pageView();
        });
    }

    handleCookieBtn() {
        window.localStorage.setItem("cookie", "1");
        this.setState({
            cookie: true,
        });
    }

    render() {
        return (
            <div className="App">
                {this.state.cookie === false &&
                <CookiesNotification>
                   <span>
                    We use cookies to improve your experience. If you continue browsing, we will take it that you have
                    agreed to their use.
                   </span>
                    <div onClick={() => this.handleCookieBtn()} className={"button_cookies"}>
                        Close
                    </div>
                </CookiesNotification>
                }
                <BrowserRouter>
                    <ScrollToTop>
                        <Header/>
                        <Switch>
                            <Route path={"/story"} component={StoryPage} exact/>
                            <Route path={"/mission"} component={MissionPage} exact/>
                            <Route path={"/passion"} component={PassionPage} exact/>
                            <Route path={"/automation"} component={AutomationPage} exact/>
                            <Route path={"/digital_transformation"} component={DigitalTransformationPage} exact/>
                            <Route path={"/testing"} component={SoftwareTestingPage} exact/>
                            <Route path={"/mobile"} component={MobilePage} exact/>
                            <Route path={"/web"} component={WebDevelopmentPage} exact/>
                            <Route path={"/custom_software"} component={CustomPage} exact/>
                            <Route path={"/technologies"} component={UsedTechnologiesPage} exact/>
                            <Route path={"/devops"} component={DevOpsPage} exact/>
                            <Route path={"/agile"} component={AgilePage} exact/>
                            <Route path={"/partners"} component={AlliancesPage} exact/>
                            <Route path={"/"} component={HomePage} exact/>
                            <Route component={ErrorPage}/>
                        </Switch>
                        <Footer/>
                    </ScrollToTop>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
