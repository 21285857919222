import React, {Component} from 'react';
import styled from 'styled-components';
import devops from '../assets/technologies/devops.jpg';
import git from '../assets/technologies/git.jpg';
import intellij from '../assets/technologies/intellij.jpg';
import ios from '../assets/technologies/ios.jpg';
import android from '../assets/technologies/android.jpg';
import java from '../assets/technologies/java.jpg';
import jenkins from '../assets/technologies/jenkins.jpg';
import jetbrains from '../assets/technologies/jetbrains.jpg';
import jira from '../assets/technologies/jira.jpg';
import jquery from '../assets/technologies/jquery.jpg';
import laravel from '../assets/technologies/laravel.jpg';
import maven from '../assets/technologies/maven.jpg';
import mongodb from '../assets/technologies/mongodb.jpg';
import vue from '../assets/technologies/vuejs.jpg';
import spark from '../assets/technologies/spark.jpg';
import npm from '../assets/technologies/npm.jpg';
import nodejs from '../assets/technologies/nodejs.jpg';
import react from '../assets/technologies/react.jpg';
import mysql from '../assets/technologies/mysql.jpg';
import sketch from '../assets/technologies/sketch-logo.jpg';
import zeplin from '../assets/technologies/zeplin-logo.jpg';


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 10px 0;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
  
  h1{
    font-family: "Raleway Thin",sans-serif;
    font-size: 28pt;
    color: dimgrey;
    width: 100%;
    text-align: left;
    padding: 20px 0;
  }
  
 
`;

const IconsContainer = styled.div`
  width: 80%;
  padding: 20px 10%;
  min-height: 600px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  
  img{
    height: 100px;
    margin-left: 30px;
    margin-right: 30px;
  }
  div{
    width: 100px;
    height: 100px;
  }
  
  @media screen and (max-width: 1000px) and(min-width: 501px){
    img{
      height: 70px;
      margin-left: 30px;
      margin-right: 30px;
    }
    div{
      width: 100px;
      height: 70px;
    }
    width: 94%;
    padding: 10px 3%;
  }
  @media screen and (max-width: 500px){
    img{
      height: 50px;
      margin-left: 10px;
      margin-right: 10px;
    }
    div{
      height: 50px;
      width: 70px;
    }
    width: 94%;
    padding: 10px 3%;
  }
`;


export default class UsedTechnologiesPage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <h1>Technologies We Use</h1>
                    <IconsContainer>
                        <img alt={"tech_logo"} src={devops}/>
                        <img alt={"tech_logo"} src={git}/>
                        <img alt={"tech_logo"} src={intellij}/>
                        <img alt={"tech_logo"} src={java}/>
                        <img alt={"tech_logo"} src={maven}/>
                        <img alt={"tech_logo"} src={jenkins}/>
                        <img alt={"tech_logo"} src={laravel}/>
                        <img alt={"tech_logo"} src={jetbrains}/>
                        <img alt={"tech_logo"} src={jira}/>
                        <img alt={"tech_logo"} src={nodejs}/>
                        <img alt={"tech_logo"} src={ios}/>
                        <img alt={"tech_logo"} src={android}/>
                        <img alt={"tech_logo"} src={jquery}/>
                        <img alt={"tech_logo"} src={mongodb}/>
                        <img alt={"tech_logo"} src={vue}/>
                        <img alt={"tech_logo"} src={spark}/>
                        <img alt={"tech_logo"} src={npm}/>
                        <img alt={"tech_logo"} src={react}/>
                        <img alt={"tech_logo"} src={mysql}/>
                        <img alt={"tech_logo"} src={zeplin}/>
                        <img alt={"tech_logo"} src={sketch}/>
                    </IconsContainer>
                </Container>
            </PageContainer>
        );
    }
}