class EventSystem {
    static queue = {};

    static publish(event, data) {
        if (typeof EventSystem.queue === 'undefined') {
            return false;
        }
        if (Object.keys(EventSystem.queue).length > 0) {
            if (typeof EventSystem.queue[event] !== 'undefined' && EventSystem.queue[event].length > 0)
                for (let a of EventSystem.queue[event])
                    a(data);
        }
    }

    static subscribe(event, callback) {
        if (typeof EventSystem.queue[event] === 'undefined') {
            EventSystem.queue[event] = [];
        }

        EventSystem.queue[event].push(callback);
    }
}

export default EventSystem;