import React, {Component} from 'react';
import styled from 'styled-components';
import ContactUs from "../components/ContactUs";
import TextContainer from "../components/TextContainer";


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 30px;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
`;

export default class WebDevelopmentPage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <TextContainer>
                        <h1>Web Development</h1>
                        <p>
                            First of all, check out our page... ;)
                        </p>
                        <h2>What does web development and website development mean?</h2>
                        <p>
                            In practice, web development is the creation of a unique web page, largely the creation of a
                            unique web page based on web development work. It is often the case that web developers work
                            with ready-made, semi-finished code, modules, so you do not have to start from scratch. In
                            any case, web development is considered to be the most costly form of website creation, but
                            it has the advantage of being able to create a website that is completely appropriate to the
                            purpose and task.
                        </p>
                        <h2>What to look for before you decide on web development?</h2>
                        <p>
                            While web development is one of the best ways to create a professional website that
                            meets your intended purpose, it is one of the most expensive methods. What's more, it
                            depends a lot on the development team's expertise and on what you should do in advance. So
                            the first thing we have to think about do we need a custom website? In most cases, even the
                            most complex tasks can be solved with different universal applications. For example, using
                            CMS systems, you can create almost any web page, some of which also support the
                            operation of web stores. In addition to CMS systems, there are many online services that
                            can be used to create simpler websites.
                        </p>
                        <h2>First steps</h2>
                        <p>
                            There are hundreds of results on Google for the word web development or web development
                            pricing, which means a lot of companies that deal with web development. However, in addition
                            to price, competence is also an important consideration. Therefore, as a first step, ask for
                            a quote on the work to be done and you can exclude companies that do not respond
                            within a few days. They are probably so busy that they will not have time to complete our
                            project. However, the pricing is not everything you should consider.
                        </p>
                        <h2>Speed</h2>
                        <p>
                            We are already thinking about speed in the design phase of web development. This is
                            manifested either by choosing a CMS system for the purpose or by creating a custom system
                            for the purpose. For example, the database used by the wordpress CMS system is great for
                            displaying and managing content, so if you have a lot of content, this can be a good choice.
                            But if you are designing a webshop or a more sophisticated customer management system,
                            Drupal CMS might be a better choice as the database it uses is beneficial for other types of
                            tasks. These aspects need to be discussed with the developer, please contact us for a
                            meeting so we can consult in more details.
                        </p>
                        <h2>Is it responsive?</h2>
                        <p>
                            Today, 40-50% of internet traffic is created by mobile phones, so 40-50% of the visitors to
                            our newly created website will probably open their website via mobile phones. This is
                            something we need to keep in mind during the first steps of development, as it can be costly
                            to redesign later. There are several ways to make a website mobile-friendly. One popular
                            solution is to use responsive design, but this may not be a good option for more complex
                            pages.
                        </p>
                        <h2>What about SEO?</h2>
                        <p>
                            The essence of search engine optimization is to improve the appearance of our website in the
                            search results list. This is available within the site using methods known as the so-called
                            on-page SEO techniques, but in addition to many pages, there are elements (off-page SEO) of
                            search engine optimization. It is advisable to pay attention to on-page SEO elements in the
                            early stages of website development so that you do not have to touch
                            them later.
                        </p>
                    </TextContainer>
                </Container>
                <ContactUs/>
            </PageContainer>
        );
    }
}