import React, {Component} from 'react';
import styled from 'styled-components';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import messages from "../assets/contact_us_messages.jpg";
import EmailValidator from "../utils/EmailValidator";
import APICaller from "../utils/APICaller";

const Wrapper = styled.div`
  position:relative;
  top: 30px;
  width: 100%;
  img{
    position:absolute;
    top: 100px;
    left: 5%;
    z-index: -1;
  }
  .form{
    margin-left: 45%;
    width: 50%;
    padding: 25px;
    box-sizing: border-box;
    min-height: 300px;
    background-color: #282c34;
    z-index: 11;
    p{
      color: whitesmoke;
      font-family: "Raleway", sans-serif;
      font-size: 17pt;
      width: 100%;
      text-align: center;
    }
    box-shadow: 0 10px 14px 0 rgba(40,44,52,0.74);
  }
  h2{
    position:absolute;
    top: 0;
    left: 5%;
    width: fit-content;
    text-align: left;
    color: #282c34;
    font-family: "Segoe UI Light", sans-serif;
    font-size: 29pt;
    padding: 0;
    &:after{
      content: '';
      width: 100%;
      margin-left: 30px;
      height: 6px;
      background-color: #94cce7;
      display: block;
      top: 100%;
      left: 0;
    }
    @media screen and (max-width: 1200px){
      font-size: 25pt;
      
      &:after{
        width: 90%;
      }
    }
  }
  
  @media screen and (max-width: 1000px) {
    display: block;
    h2{
      position:unset;
    }
    .form{
      position:unset;
      width: 100%;
      margin: 0;
      padding: 25px 50px;
    }
    img{
      margin-top:0;
      position:unset;
      width: 100%;
      height: 40vh;
      object-fit: cover;
    }

  }
`;
const Button = styled.div`
    font-size: 16pt;
    color: white;
    padding: 10px 25px;
    margin: 20px 0 5px auto;
    border: 2px solid white;
    border-radius: 30px;
    
    width: fit-content;
    
    &:hover{
      background-color: white;
      color: #002c47;
      cursor: pointer;
      border: 2px solid #002c47;
    }
`;
const Input = styled(TextField)`
    color: white;
    width: 100%;
    padding: 20px 0;
    
    label{
      color: white;
      font-family: "Segoe UI", sans-serif;
    }
    label.Mui-focused{
      color: white;
    }
    
    div>input, div{
      color: white !important;
      height: 40px;
      text-align: left;
      font-family: "Segoe UI", sans-serif;
      font-size: 16pt;
    }
    
    .MuiInput-underline:after{
      border-bottom-color: #94cce7;
    }
    
    .MuiInput-underline:before{
      border-bottom-color: white;
    }
    
    svg{
      fill: white;
    }
`;

const Item = styled(MenuItem)`
    color: white;
`;

const MultiLineInput = styled(Input)`
    div>input, div{
      color: white !important;
      height: 125px;
    }
`;

const topics = [
    {value: '1', label: 'Web development'},
    {value: '2', label: 'Custom software'},
    {value: '3', label: 'IT Consulting'},
    {value: '4', label: 'Industrial automation & optimization'},
    {value: '5', label: 'Mobile Application'},
    {value: '6', label: 'Software Testing'},
    {value: '6', label: 'Software Operation'},
    {value: '7', label: 'Other'},
];

export default class ContactUs extends Component {
    state = {
        name: "",
        phone: "",
        email: "",
        topic: "",
        idea: "",
    };

    static checkInput(value) {
        return value.length < 5 && value.length > 0;
    }

    checkName() {
        return this.state.name.length <= 3;
    }

    checkTel() {
        return this.state.phone.length < 7;
    }

    send() {
        if (this.checkName() || !EmailValidator(this.state.email) || this.checkTel())
            return;

        let topicName = topics.find(topic => topic.value === this.state.topic).label;

        APICaller.sendContactForm(
            this.state.name,
            this.state.email, this.state.phone,
            this.state.idea, topicName
        );

        this.setState({
            name: '',
            phone: '',
            email: '',
            idea: '',
            topic: '',
        })
    }

    render() {
        return (
            <Wrapper>
                <h2>Let's launch YOUR project!</h2>
                <img alt={"messages.jpg"} src={messages}/>
                <div className={"form"}>
                    <p>Tell us about it and we will kick-start it!</p>
                    <form>
                        <Input label={"How can I call you"} id={"name"} required type={"name"}
                               error={ContactUs.checkInput(this.state.name)}
                               onChange={(e) => this.setState({name: e.target.value})}
                               value={this.state.name}
                        />
                        <Input label={"Phone number"} id={"phone"} required type={"phone"}
                               error={ContactUs.checkInput(this.state.phone)}
                               onChange={(e) => this.setState({phone: e.target.value})}
                               value={this.state.phone}
                        />
                        <Input label={"E-mail address"} id={"email"} required type={"email"}
                               error={this.state.email.length > 0 && !EmailValidator(this.state.email)}
                               onChange={(e) => this.setState({email: e.target.value})}
                               value={this.state.email}
                        />

                        <Input
                            id="standard-select-currency"
                            select
                            label="Topic"
                            value={this.state.topic}
                            onChange={e => this.setState({topic: e.target.value})}
                            margin="normal"
                        >
                            {topics.map(option => (
                                <Item key={option.value} value={option.value}>
                                    {option.label}
                                </Item>
                            ))}
                        </Input>
                        <MultiLineInput className={"multiline"} label={"Give us some insights"} id={"idea"} required
                                        type={"text"}
                                        error={ContactUs.checkInput(this.state.idea)} multiline rowsMax="4"
                                        onChange={(e) => this.setState({idea: e.target.value})}
                                        value={this.state.idea}
                        />
                        <Button onClick={()=>this.send()}>Send <i className="far fa-paper-plane"/></Button>
                    </form>
                </div>
            </Wrapper>
        );
    }
}