import React, {Component} from 'react';
import styled from 'styled-components';
import ContactUs from "../components/ContactUs";
import TextContainer from "../components/TextContainer";


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 30px;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
`;

export default class MobilePage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <TextContainer>
                        <h1>iOS and Android App development</h1>
                        <h2>Why developing a mobile app?</h2>
                        <p>
                            Yesterday, we looked at how smartphone usage is changing in Hungary. It turns out that
                            mobile devices are part of our everyday lives, always with us, to keep in touch with our
                            friends, manage our business, listen to music, take photos, reply to letters, share and
                            more. One thing is for sure: live most of our lives on our mobile phone. How is this market?
                            Is it worth is developing an app? What types of applications can we compete with?
                            <br/>
                            <br/>
                            Mobile web usage has been growing dynamically in the last few years. Nowadays, there is no
                            business that would not be present in the mobile web appearance with the old computer look,
                            as the time spent on the smartphone is constantly increasing. If we look at the mobile
                            application market, we can also see that all media interfaces, major service providers (eg
                            banks) have mobile applications. Previously, the mobile platform was always the first one
                            for developers, not because of the future, but because users access content through this
                            platform.
                        </p>
                        <h2>Does mobile app development suit my needs?</h2>
                        <p>
                            It is not worth going for application, in my opinion, only if the company offers the kind of
                            service that offers a daily solution to a certain target group. For small businesses, they
                            should consider the mobile web platform as a form of communication. You can sign up for
                            Facebook or Google's advertising system, which will help you reach the user and take the
                            marketing part of it. The advertising market is constantly growing on mobile platforms, so
                            it's worth thinking about.
                            <br/>
                            <br/>
                            It is worthwhile to create an application if we can provide the callers with the daily
                            target of the given target group that is being prepared in their life that they will trigger
                            the need to open the application on a daily basis. You need to understand the needs of your
                            users, and if the application responds, you may want to think about how to improve it. If
                            you are only able to answer 1-2x a month, you might want to think in a mobile web interface.
                        </p>
                        <h2>What is cross-platform development?</h2>
                        <p>
                            One of the biggest challenges for developers in the past has been the development of
                            cross-platform applications, but today that has changed. Introducing the nine best
                            platform-independent mobile application development tools to help you build great apps in a
                            short space of time.
                            <br/>
                            <br/>
                            The biggest advantage of these tools is that the software developer can write the code once
                            on a single platform and then run it on different platforms. The main goal is to save time
                            and energy. Software developers can then recycle their legacy code and design applications
                            that work efficiently on Android, iOS or even Windows.
                        </p>
                        <h2>You have more questions? We can help you decide what is the best and most efficient solution
                            for you!</h2>
                    </TextContainer>
                </Container>
                <ContactUs/>
            </PageContainer>
        );
    }
}