import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';
import story_icon from '../assets/story.png';
import company_image from '../assets/company.png';
import ColoredIcon from "../components/ColoredIcon";
import ContactUs from "../components/ContactUs";
import {NavLink} from "react-router-dom";


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const storyImgKeyframes = keyframes`
  0%{
    top: -100px;
  }
  100%{
    top: -50px;
  }
`;

const Container = styled.div`
  margin: 30px;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
  
  img{
    position:absolute;
  }
  
  .img_story{
    width: 300px;
    top:-100px;
    left: calc(100% - 90px - 300px - 4%);
    z-index: 12;
    animation: ${storyImgKeyframes} 1.5s ease-in-out 0s infinite alternate;
  }
  
  .img_company{
    width: 500px;
    top: calc(100px - 100px);
    left: calc(100% - 500px - 4%);
    z-index: 11;
  }
`;

const TextContainer = styled.div`
  width: 75%;
  min-height: 600px;
  
  h1{
    font-family: "Raleway Thin",sans-serif;
    font-size: 28pt;
    color: dimgrey;
    width: 100%;
    text-align: left;
    padding: 20px;
  }
  p{
    text-align: justify;
    line-height: 1.5;
    width: 60%;
    font-family: "Segoe UI",sans-serif;
    color: #282c34;
    font-size: 14pt;
  }
`;


export default class MissionPage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <img className={"img_story"} alt={"story_icon"} src={story_icon}/>
                    <img className={"img_company"} alt={"company_image"} src={company_image}/>
                    <TextContainer>
                        <h1>Our vision</h1>
                        <p>
                        </p>
                    </TextContainer>
                </Container>
            </PageContainer>
        );
    }
}