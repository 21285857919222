import React, {Component} from 'react';
import styled from 'styled-components';
import ContactUs from "../components/ContactUs";
import TextContainer from "../components/TextContainer";


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 30px;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
`;


export default class CustomPage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <TextContainer>
                        <h1>Custom software development</h1>
                        <p>
                            In our unique software development projects, we maximize the customer's needs and build on
                            the experience gained over the past few years to develop the solution that best follows the
                            desired workflow.
                            We aim to use the latest methods and technologies, so besides the traditional software
                            development models, the agile software development methodology also helps our work. In order
                            to fully meet our customer's needs, we established our testing business in 2005, working
                            closely with our software development business.
                            In our testing, we follow the agile methodology and the V-model, and have considerable
                            experience in automating and documenting testing.
                        </p>
                        <h2>Development methodologies</h2>
                        <p>
                            Our development and testing team uses a variety of methodologies (waterfall model, V model,
                            Agile methodology), depending on the project, during development and testing work.
                            <br/>
                            <br/>
                            The <b>waterfall model</b> is characterized by the fact that tasks are performed
                            sequentially, from a feasibility study through implementation of various project tasks to
                            the user environment.
                            <br/>
                            <br/>
                            Using the <b>V model</b>, testing begins at the earliest stages of the life cycle. In
                            addition, various activities are already carried out in parallel with development
                            activities.
                            <br/>
                            <br/>
                            In the <b>Agile methodology</b>, participants try to adapt as much as possible to the
                            project. Key features include less documentation, increased flexibility, reduced risk,
                            easier communication, improved collaboration and customer involvement in development.
                        </p>
                        <h2>Platforms, Java</h2>
                        <p>
                            When designing our Java applications, we place great emphasis on flexible, modularized
                            architectural design and the incorporation of modern technology. This makes it easy to
                            integrate our products into our customers' diverse operating environments, even the most
                            complex SOA-based architectures. We use the most advanced development tools to work quickly,
                            efficiently and with outstanding quality. As part of our quality assurance system, our build
                            environment performs continuous code integration, automated testing, code coverage
                            measurement, and static code validation to ensure our development colleagues deliver high
                            quality software. Languages, technologies, tools used:
                            <ul>
                                <li>Java EE</li>
                                <li>Spring Framework</li>
                                <li>JPA, Hibernate</li>
                                <li>Maven</li>
                                <li>Idea IntelliJ</li>
                                <li>JPA</li>
                                <li>JWT</li>
                                <li>Version control: Git</li>
                                <li>MySQL, OracleSQL</li>
                                <li>JSON</li>
                                <li>SOAP</li>
                            </ul>
                        </p>
                        <h2>Platforms, Microsoft</h2>
                        <p>
                            In custom software development, our process control tasks are solved with our continuous SQL
                            / .NET workflow engine. We have experience in developing international document management
                            applications and processes.

                            Our development teams include multiple years of experience as project manager, software
                            design mathematicians, business analysts, actuarial model editors, senior .NET developers,
                            XML / XSLT editors, testers. Languages, technologies, tools used:
                            <ul>
                                <li>C++, C#</li>
                                <li>.NET</li>
                                <li>.NET Core</li>
                                <li>XML, XSLT, SOAP</li>
                                <li>MsSQL</li>
                                <li>Visual Studio, Code</li>
                                <li>Git</li>
                            </ul>
                        </p>
                        <h2>Want to know more about us? Why not talk about it?</h2>
                    </TextContainer>
                </Container>
                <ContactUs/>
            </PageContainer>
        );
    }
}