import React, {Component} from 'react';
import logo from '../../assets/logo_transparent_320.png';
import styled from 'styled-components';

const NavigationLogo = styled.div`
    display: block;
    height: 100%;
    width: auto;
    margin-left: 15px;
    
    img{
        box-sizing:border - box;
        height: 100%;
    }
`;

class HeaderLogo extends Component {

    render() {
        return (
            <NavigationLogo>
                <img alt={"logo"} src={logo}/>
            </NavigationLogo>
        );
    }
}

export default HeaderLogo;