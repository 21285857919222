import React, {Component} from 'react';
import EventSystem from '../../utils/EventSystem';
import styled, {css} from 'styled-components';

const Hamburger = styled.div`
    margin-right: 10px;
    width: 45px;
    height: 45px;

    transform: scale(0.65, 0.65);

    position: relative;
    cursor: pointer;
    
    span {
        display: block;
        position: absolute;
        height: 7px;
        width: 100%;
        background: #1eb5ea;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
    }
    
    span:nth-child(1) {
        top: 0;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }
    
    span:nth-child(2) {
        top: 14px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }
    
    span:nth-child(3) {
        top: 28px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }
    
    ${({menuOpened}) => menuOpened && css`

        span {
            background: white;
        }
        span:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: -3px;
            left: 8px;
        }

        span:nth-child(2) {
            width: 0;
            opacity: 0;
        }

        span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 29px;
            left: 8px;
        }
    `}
`;

const HamburgerContainer = styled.div`
    display: none;
    align-self: flex-end;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 2;
    height: 100%;
    
    @media screen and (max-width: 951px){
      display: flex;
    }
`;

class HamburgerMenuIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {menuOpened: (this.props.open === true)};
    }

    toggleMenu() {
        this.setMenuState(!this.state.menuOpened);
    }

    updateDimensions() {
        if (window.innerWidth > 951)
            this.setMenuState(false)
    }

    setMenuState(open) {
        this.setState({menuOpened: open});
        this.props.menuStateChanged(open);
    }

    componentDidMount() {
        EventSystem.subscribe('urlChanged', () => {
            this.setMenuState(false);
        });

        window.addEventListener("resize", () => this.updateDimensions.bind(this));
        window.addEventListener("orientationchange", () => this.setMenuState(false));
        this.setState({menuOpened: (this.props.open === true)});
    }

    render() {
        return (
            <HamburgerContainer menuOpened={this.state.menuOpened}>
                <Hamburger menuOpened={this.state.menuOpened} onClick={() => this.toggleMenu()}>
                    <span/>
                    <span/>
                    <span/>
                </Hamburger>
            </HamburgerContainer>
        );
    }
}

export default HamburgerMenuIcon;