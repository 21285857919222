import React, {Component} from 'react';
import styled from 'styled-components';
import ContactUs from "../components/ContactUs";
import TextContainer from "../components/TextContainer";


const PageContainer = styled.div`
  margin-top: 65px;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 30px;
  width: 90%;
  padding: 20px 25px;
  position:relative;
  min-height: 60vh;
`;

export default class AutomationPage extends Component {
    render() {
        return (
            <PageContainer>
                <Container>
                    <TextContainer>
                        <h1>Automation</h1>
                        <p>
                            The demands placed on manufacturing companies are increasing, leading to the need for
                            flexible manufacturing processes and to the car. The solution lies in the manufacturing
                            process. OpThink can help you optimally prepare for the present and future challenges of
                            automation.
                        </p>
                        <h2>New demands in your market</h2>
                        <p>
                            Global competition and digital transformation are significantly increasing the demands
                            placed on manufacturing companies. While new products and product variants are changing
                            rapidly in the marketplace, easy online comparison of offers is increasingly transforming
                            customer expectations for product production.

                            To meet customer needs, manufacturers need to provide a huge, yet volatile, product range.
                            In addition, pressures are increasing due to a shortage of professionals, rising wage costs
                            and stringent health and safety requirements. Manufacturing companies therefore need
                            flexible production systems - the key to this is automation of production.

                            Automation means improving the manufacturing process with machines and modern technologies.
                            As a specialist in software automation of manufacturing, OpThink allows you to keep up with
                            the increased demands on speed, flexibility, economy and value creation.
                        </p>
                        <h2>Synchronizing real and virtual production</h2>
                        <p>
                            Our company is able to provide solutions for IoT interfaces used in professional networked
                            production systems, complementing its competencies with a comprehensive palette of
                            Industrial Internet of Things (IIoT).
                        </p>
                        <h2>Need a custom tailored solution for your business to roll? Contact us!</h2>
                    </TextContainer>
                </Container>
                <ContactUs/>
            </PageContainer>
        );
    }
}